import React from 'react';
import styles from './Section6.module.css';
import foodImage from '../../Images/non_veg_platter.png'; // Use the correct path to your image
import { useNavigate } from 'react-router-dom';

const Section6 = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/about');
    };

    return (
        <div className={styles.section}>
            <div className={styles.content}>
                <h2 className={styles.heading}>OUR STORY</h2>
                <p className={styles.subheading}>We operate our business under these guiding principles:</p>
                <p className={styles.text}>
                    With a steadfast commitment to quality and tradition, we continue to deliver the same delightful flavors that have enchanted our guests for decades.
                </p>
                <p className={styles.text}>
                    Whether you crave the rich, aromatic spices of Mughlai cuisine, the bold and diverse tastes of Chinese food, or the smoky, grilled perfection of Tandoori specialties, our menu offers an authentic experience that remains unchanged and consistently delicious. </p>
                <button className={styles.button} onClick={handleButtonClick}>More About Us</button>
            </div>
            <div className={styles.imageContainer}>
                <img src={foodImage} alt="Delicious food" className={styles.image} />
            </div>
        </div>
    );
};

export default Section6;
