import React from 'react';
import styles from './ContactSection3.module.css';
import { FaPhone, FaEnvelope, FaFacebookF, FaTwitter, FaInstagram, FaMapMarkerAlt } from 'react-icons/fa';

const ContactSection3 = () => {
    return (
        <div className={styles.container}>
            <div className={styles.location}>
                <h2>Our Location</h2>
                <p><FaMapMarkerAlt /> Address-42, DLF Industrial Area, Kirti Nagar, New Delhi - 110015</p>
                <a href="https://maps.app.goo.gl/HjoQmBKeYjZ5FXHVA" target="_blank" rel="noopener noreferrer" className={styles.direction}>Get Direction</a>
            </div>
            <div className={styles.contact}>
                <h2>Contact Us</h2>
                <div className={styles.paragraph}>
                <p><FaPhone /> +91 9289356679</p>
                </div>
                <div className={styles.paragraph}>
                <p><FaPhone /> +91 7610001310</p>
                </div>
                
                <div className={styles.socialMedia}>
                    <a href="https://www.instagram.com/bawa.chicken/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                </div>
            </div>
        </div>
    );
};

export default ContactSection3;
