import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import PageNotFound from "../PageNotFound/PageNotFound";
import Menu from "../Menu/Menu";
import About from "../About/About";
import Contact from "../Contact/Contact";
import PrivateRoute from "./PrivateRoutes";
import Orders from "../Orders/Orders";
import DeliveryBoy from "../DeliveryBoy/DeliveryBoy";
import CouponCode from "../CouponCode/CouponCode";
import TermsAndCondition from "../TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";

const AppRoutes = () => {

    const userRole = localStorage.getItem("userRole");

    return (
        <div>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/order-now" element={<Menu />} />
                        <Route path="*" element={<PageNotFound />} />
                        <Route path="/terms-and-condition" element={<TermsAndCondition />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                        <Route path="/orders" element={
                            <PrivateRoute allowedRoles={[1, 2, 3]}>
                                <Orders />
                            </PrivateRoute>
                        } />

                        <Route path="/delivery-boy" element={
                            <PrivateRoute allowedRoles={[1]}>
                                <DeliveryBoy />
                            </PrivateRoute>
                        } />

                        <Route path="/coupon" element={
                            <PrivateRoute allowedRoles={[1]}>
                                <CouponCode />
                            </PrivateRoute>
                        } />

                    </Routes>
                </div>
            </Router>
        </div>
    );
}

export default AppRoutes;