import React from 'react';
import styles from './AboutSection3.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faShoppingBag, faMotorcycle, faBellConcierge } from '@fortawesome/free-solid-svg-icons';

const AboutSection3 = () => {
  const items = [
    { icon: faUtensils, label: 'DINING' },
    { icon: faShoppingBag, label: 'TAKEAWAY' },
    { icon: faMotorcycle, label: 'DELIVERY' },
    { icon: faBellConcierge, label: 'TALENTED CHEF' },
  ];

  return (
    <div className={styles.aboutSection}>
      {items.map((item, index) => (
        <div key={index} className={styles.iconContainer}>
          <FontAwesomeIcon icon={item.icon} className={styles.icon} />
          <div className={styles.label}>{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export default AboutSection3;
