import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ContactSection2.module.css';

const ContactSection2 = () => {
    const navigate = useNavigate();

    const handleOrderNowClick = () => {
        navigate('/order-now');
    };

    return (
        <div className={styles.container}>
            <div className={styles.map}>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d112036.3446186932!2d77.0606366938586!3d28.655653165447156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x390d0303f436b4c7%3A0x8fd3cd0d59e8b195!2sM%2C%2C%20Natraj%20Road%2C%20Block%20J%2C%20Kirti%20Nagar%2C%2042%2C%20DLE%20Industrial%20Area%2C%20Kirti%20Nagar%2C%20New%20Delhi%2C%20Delhi%20110015!3m2!1d28.6556781!2d77.1430381!5e0!3m2!1sen!2sin!4v1726256649907!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>
            <div className={styles.info}>
                <h2>We Are Open</h2>
                <p><strong>Bawa Chicken : The Journey Of Spice</strong></p>
                <p>Monday to Sunday</p>
                <p>12:30 PM -3:30 PM & 6:00 PM-11:30 PM</p>
                <p>Tuesday - OFF</p>

                <button className={styles.orderButton} onClick={handleOrderNowClick}>Order Now</button>
            </div>
        </div>
    );
};

export default ContactSection2;
