import React, { useEffect, useState } from 'react';
import styles from './ViewCart.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faLocationArrow, faSpinner, faEdit, faTimes, faTruck, faShoppingBag, faUtensils, faMotorcycle, faHome } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../../api';
import OrderPopup from './OrderPopup';
import { useNavigate } from 'react-router-dom';
import { initiateRazorpayPayment } from '../../razorpay';

const ViewCart = ({ cartItems, handleQuantityChange, handleRemoveItem, handleCloseViewCart }) => {
  const navigate = useNavigate();
  const total = cartItems.reduce((sum, item) => {
    if (item.variantId) {
      return sum + item.variantDiscountPrice * item.quantity;
    } else {
      return sum + item.item.discountPrice * item.quantity;
    }
    return sum; // In case no variant found, return the sum as it was
  }, 0);


  const [address, setAddress] = useState({
    street: '',
    landmark: '',
    pincode: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showOrderPopup, setShowOrderPopup] = useState(false);
  const [orderMessage, setOrderMessage] = useState('');
  const [addressError, setAddressError] = useState('');
  const [addressSaved, setAddressSaved] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [paymentMode, setPaymentMode] = useState(null);
  const [availablePaymentModes, setAvailablePaymentModes] = useState([]);
  const [error, setError] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [couponMessage, setCouponMessage] = useState('');
  const [discount, setDiscount] = useState(0);
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [additionalMessage, setAdditionalMessage] = useState('');
  const [orderTypes, setOrderTypes] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState(1);
  const [hideDeliveryDetails, setHideDeliveryDetails] = useState(false);
  const [orderTypeError, setOrderTypeError] = useState('');
  const tax = (total * 0.05 + deliveryCharges * 0.18).toFixed(2);
  const grandTotalBeforeRoundOff = (total + deliveryCharges + parseFloat(tax)).toFixed(2);
  // const roundOffAmount = (grandTotalBeforeRoundOff - Math.floor(grandTotalBeforeRoundOff)).toFixed(2);
  // const grandTotal = grandTotalBeforeRoundOff - roundOffAmount;
  const [roundOffAmount, setRoundOffAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const [availableCoupons, setAvailableCoupons] = useState([]);
  const [minimumOrder, setMinimumOrder] = useState(0);
  const [distance, setDistance] = useState(0);
  const [maximumDistance, setMaximumDistance] = useState(0);
  const [validateDistanceAndAmount, setValidateDistanceAndAmount] = useState(false);
  const [checkoutError, setCheckoutError] = useState('');
  const [previousAddresses, setPreviousAddresses] = useState([]);
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [scheduleDateTime, setScheduleDateTime] = useState('');

  const handleDateTimeChange = (e) => {
    setScheduleDateTime(e.target.value);
  };


  useEffect(() => {
    fetchPreviousAddresses();
  }, []);

  const fetchPreviousAddresses = async () => {
    try {
      const response = await axiosInstance.get(process.env.REACT_APP_USER_ADDRESS, {});

      if (response.data.responseStatus && response.data.responseObject.length > 0) {
        setPreviousAddresses(response.data.responseObject);
      }
    } catch (error) {
      console.error('Error fetching previous addresses:', error);
    }
  };

  useEffect(() => {
    document.body.classList.add('modalOpen');
    return () => {
      document.body.classList.remove('modalOpen');
    };
  }, []);

  useEffect(() => {
    if (cartItems.length === 0) {
      handleCloseViewCart();
    }
  }, [cartItems, handleCloseViewCart]);

  useEffect(() => {
    if (addressError) {
      const timer = setTimeout(() => {
        setAddressError('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [addressError]);

  useEffect(() => {
    if (checkoutError) {
      const timer = setTimeout(() => {
        setCheckoutError('');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [checkoutError]);

  useEffect(() => {
    // Fetch order types on component mount
    const fetchOrderTypes = async () => {
      try {
        const response = await axiosInstance.get(process.env.REACT_APP_ORDER_TYPE, {});

        if (response.data.responseStatus) {
          const validOrderTypes = response.data.responseObject
            .filter((order) => [1, 2, 3].includes(order.orderType)); // Filter for valid order types
          setOrderTypes(validOrderTypes);
        }
      } catch (error) {
        console.error('Error fetching order types:', error);
      }
    };

    fetchOrderTypes();
  }, []);
  const handleOrderTypeChange = (orderType) => {
    setSelectedOrderType(orderType);

    // Hide delivery details for Take Away (2) and Dine In (3)
    if (orderType === 2 || orderType === 3) {
      setHideDeliveryDetails(true);
      setDeliveryCharges(0); // Set delivery charges to 0
      setValidateDistanceAndAmount(false);
      setAddress({
        street: '',
        landmark: '',
        pincode: ''
      });
      setAddressSaved(false); // Reset address saved status
    } else {
      setHideDeliveryDetails(false); // Show delivery details for Delivery (1)
    }
  };


  useEffect(() => {
    return () => {
    };
  }, [selectedOrderType]);

  useEffect(() => {
    // API call when ViewCart is opened
    axiosInstance.post(process.env.REACT_APP_PAYMENT_MODE, {}, {})
      .then(response => {
        const mode = response.data.responseObject;

        switch (mode) {
          case 1:
            setAvailablePaymentModes([{ id: 1, label: 'Cash on Delivery' }]);
            break;
          case 2:
            setAvailablePaymentModes([{ id: 2, label: 'Pay Online' }]);
            break;
          case 3:
            setAvailablePaymentModes([
              { id: 1, label: 'Cash on Delivery' },
              { id: 2, label: 'Pay Online' }
            ]);
            break;
          default:
            setError('Invalid payment mode');
        }
      })
      .catch(error => {
        console.error('Error fetching payment mode:', error);
      });
  }, []);

  useEffect(() => {
    // Update discount and grand total when coupon changes or subtotal changes
    const minOrderAmount = 0; // Default to 0 if no coupon is applied
    if (isCouponApplied) {
      axiosInstance.get(`${process.env.REACT_APP_COUPONCODE_VALIDATE}`, {
        params: { couponcode: couponCode }
      })
        .then(response => {
          const { minimumOrder } = response.data.responseObject;
          if (total < minimumOrder) {
            // Automatically remove coupon if subtotal < minimumOrder
            setIsCouponApplied(false);
            setDiscount(0);
            setCouponCode('');
            setCouponMessage('Coupon removed as the subtotal is below the minimum order amount.');
          }
        })
        .catch(error => {
          console.error('Error validating coupon:', error);
        });
    }

    // Apply coupon discount to the subtotal
    const subtotalAfterDiscount = total - discount;

    // Calculate taxes on the discounted subtotal
    const grandTotalBeforeTaxes = subtotalAfterDiscount + deliveryCharges;
    const totalTax = parseFloat(tax); // Assuming tax is a percentage or fixed amount
    const grandTotalBeforeRoundOff = (grandTotalBeforeTaxes + totalTax).toFixed(2);

    // Round off and calculate final grand total
    const roundOffAmount = (grandTotalBeforeRoundOff - Math.floor(grandTotalBeforeRoundOff)).toFixed(2);
    setRoundOffAmount(roundOffAmount);
    setGrandTotal(grandTotalBeforeRoundOff - roundOffAmount);

  }, [total, isCouponApplied, couponCode, deliveryCharges, tax]);


  const handlePaymentModeChange = (e) => {
    setPaymentMode(Number(e.target.value));
  };

  const handleLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const geocodingrequest = {
            latitude: latitude,
            longitude: longitude
          };
          axiosInstance.post(process.env.REACT_APP_GEOCODING_API, geocodingrequest)
            .then(response => {
              setAddress({ street: response.data.responseObject.address, pincode: response.data.responseObject.pincode });
            })
            .catch(error => {
              console.error('Error fetching address using coordinates', error);
            });
        },
        (error) => {
          console.error('Error fetching location:', error);
        }
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress({
      ...address,
      [name]: value
    });
    if (addressError && value.trim() !== '') {
      setAddressError('');
    }
  };

  const handleSaveAddress = () => {
    if (!address.street.trim()) {
      setAddressError('Please enter the delivery address.');
      return;
    }

    if (!String(address.pincode).trim()) {
      setAddressError('Please enter the pincode');
      return;
    }

    // Get delivery charges based on address
    axiosInstance.post(process.env.REACT_APP_GEOCODING_DISTANCE, {
      address: address.street,
      pincode: address.pincode
    },)
      .then(response => {
        setDeliveryCharges(response.data.responseObject.deliveryCharges); // Update delivery charges
        setDistance(response.data.responseObject.distance);
        setMinimumOrder(response.data.responseObject.minimumOrder);
        setMaximumDistance(response.data.responseObject.maximumDistance);
        setValidateDistanceAndAmount(true)
        setAddressSaved(true); // Mark address as saved
      })
      .catch(error => {
        setAddressError(error.response.data.responseMessage)
        console.error('Error fetching delivery charges:', error);
      });
  };

  const handlePlaceOrder = () => {

    if (selectedOrderType === '') {
      setOrderTypeError('Please select your order type');
      return;
    }
    if (!addressSaved && selectedOrderType === 1) {
      setAddressError('Please save the address details before placing the order.');
      return;
    }

    if (paymentMode === null) {
      setError('Please select a payment mode');
      return;
    }


    if (validateDistanceAndAmount && grandTotal < minimumOrder) {
      setCheckoutError('Minimum order value is Rs. ' + minimumOrder);
      return;
    }
    if (validateDistanceAndAmount && distance > maximumDistance) {
      setCheckoutError("We do not deliver in your area since it's far away.");
      return;
    }

    setIsLoading(true);

    const orderDetails = {

      orderItems: cartItems.map(item => ({
        itemId: item.itemId,
        quantity: item.quantity,
        variantId: item.variantId
      })),
      totalTax: (total * 0.1).toFixed(2),
      shippingCharges: deliveryCharges,
      address: address.street,
      landmark: address.landmark,
      pincode: address.pincode,
      paymentOrderId: null, // This will be updated after API call
      totalAmount: 0, // Placeholder, will be updated with the response
      orderNumber: null,
      paymentMode: paymentMode,
      additionalMessage: additionalMessage,
      couponCode: isCouponApplied ? couponCode : null,
      discount: discount,
      orderType: selectedOrderType,
    //  scheduleDate : scheduleDateTime
    };

    axiosInstance.post(process.env.REACT_APP_CREATE_ORDER, orderDetails)
      .then(response => {
        setIsLoading(false);
        orderDetails.paymentOrderId = response.data.responseObject.paymentOrderId;
        orderDetails.totalAmount = response.data.responseObject.totalAmount;
        orderDetails.orderNumber = response.data.responseObject.orderNumber;
        orderDetails.customerName = response.data.responseObject.customerName;
        orderDetails.customerPhoneNumber = response.data.responseObject.customerPhoneNumber;
        orderDetails.razorpayKeyId = response.data.responseObject.razorpayKeyId;
        if (orderDetails.paymentOrderId) {
          initiateRazorpayPayment(orderDetails,
            (paymentResponse) => {
              // On payment success
              setIsLoading(true);
              axiosInstance.post(`${process.env.REACT_APP_ORDER_STATUS_UPDATE}`, {
                orderNumber: orderDetails.orderNumber,
                status: 1,
                razorpayPaymentId: paymentResponse.razorpay_payment_id
              })
                .then(res => {
                  setIsLoading(false);
                  setOrderMessage(res.data.responseObject); // Set the message from the response
                  setShowOrderPopup(true);
                })
                .catch(error => {
                  setIsLoading(false);
                  console.error('Error updating order status:', error);
                  setOrderMessage('Payment successful, but failed to update order status');
                  closeOrderPopup();
                });

            },
            (errorMessage) => {
              // On payment failure
              setOrderMessage(`Payment failed. ${errorMessage}`);
            }
          );
        } else {
          setOrderMessage(response.data.responseObject.message);
          setShowOrderPopup(true);
        }
      })
      .catch(error => {
        console.error('Error placing order:', error);
        setIsLoading(false);
        setOrderMessage(error.response.data.responseMessage);
        setShowOrderPopup(true);
      });
  };

  const closeOrderPopup = () => {
    setShowOrderPopup(false);
    localStorage.removeItem('cartItems');
    localStorage.removeItem('quantities');
    navigate('/orders');
  };

  const handleApplyCoupon = () => {
    if (!couponCode.trim()) {
      setCouponMessage('Please enter a coupon code.');
      return;
    }

    axiosInstance.get(`${process.env.REACT_APP_COUPONCODE_VALIDATE}`, {
      params: { couponcode: couponCode }
    })
      .then(response => {
        const { isValid, minimumOrder, discount } = response.data.responseObject;
        if (isValid && total >= minimumOrder) {
          setIsCouponApplied(true);
          setDiscount(discount);
          setCouponMessage('');
        } else {
          setCouponMessage('Coupon is invalid.');
        }
      })
      .catch(error => {
        setCouponMessage('Error applying coupon. Please try again.');
        console.error('Error applying coupon:', error);
      });
  };

  const handleRemoveCoupon = () => {
    setCouponCode('');
    setIsCouponApplied(false);
    setDiscount(0);
    setCouponMessage('Coupon removed successfully.');
  };

  const fetchCoupons = async () => {
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_COUPON_LIST}`);
      if (response.data.responseStatus && response.data.responseObject) {
        setAvailableCoupons(response.data.responseObject);
      }
    } catch (error) {
      console.error('Error fetching coupon codes:', error);
    }
  };

  const handleShowCouponPopup = () => {
    setShowCouponPopup(true);
    fetchCoupons();
  };

  const handleApplyCouponChange = (couponCode) => {

    if (isCouponApplied) {
      setCouponCode('');
      setIsCouponApplied(false);
    }
    setCouponCode(couponCode); // Set the selected coupon code
    setShowCouponPopup(false); // Close the popup after applying
  }

  useEffect(() => {
    if (couponCode) {
      handleApplyCoupon();
    }
  }, [couponCode]); // This will run when couponCode changes

  const handleAddressSelect = (addr) => {
    setAddress({
      street: addr.address,
      pincode: addr.pincode,
      landmark: addr.landmark
    });
    setShowAddressPopup(false); // Close the popup
    setAddressSaved(false);
  };


  return (
    <div className={styles.modalOverlay}>
      {isLoading && (
        <div className={styles.loaderOverlay}>
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        </div>
      )}
      {showOrderPopup && (
        <OrderPopup message={orderMessage} handleClose={closeOrderPopup} />
      )}
      <div className={styles.cartContainer}>
        <div className={styles.cartHeader}>
          <h2>Your Cart ({cartItems.length} items)</h2>
          <button className={styles.closeButton} onClick={handleCloseViewCart}>×</button>
        </div>
        <div className={styles.orderTypeButtons}>
          <div className={styles.buttonGroup}>
            {orderTypes.map((orderType) => (
              <button
                key={orderType.id}
                className={`${styles.button} ${selectedOrderType === orderType.orderType ? styles.selected : ''
                  }`}
                onClick={() => handleOrderTypeChange(orderType.orderType)}
              >
                {/* Display Font Awesome icons based on orderType */}
                {orderType.orderType === 1 && (
                  <>
                    <FontAwesomeIcon icon={faMotorcycle} className={styles.icon} />
                    Delivery
                  </>
                )}
                {orderType.orderType === 2 && (
                  <>
                    <FontAwesomeIcon icon={faShoppingBag} className={styles.icon} />
                    Take Away
                  </>
                )}
                {orderType.orderType === 3 && (
                  <>
                    <FontAwesomeIcon icon={faUtensils} className={styles.icon} />
                    Dine In
                  </>
                )}
              </button>
            ))}
          </div>
          {orderTypeError && <div className={styles.errorText}>{orderTypeError}</div>}
        </div>

        <div className={styles.cartItems}>
          {cartItems.map((item, index) => {
            // Find the selected variant if present
            const selectedVariant = item.variantId
              ? true
              : false;

            return (
              <div key={index} className={styles.cartItem}>
                <img
                  src={item.item.imageUrl}
                  alt={item.item.item}
                  className={styles.itemImage}
                />
                <div className={styles.itemInfo}>
                  <h3 className={styles.itemName}>{item.item.item}{selectedVariant ? " (" + item.variantName + ")" : ""}</h3>
                  <p className={styles.itemDescription}>{item.item.itemDescription}</p>
                  <div className={styles.priceContainer}>
                    {/* Display variant price if applicable */}
                    {(item.variantOriginalPrice || item.item.originalPrice) && (
                      <span className={styles.originalPrice}>
                        ₹{selectedVariant ? item.variantOriginalPrice : item.item.originalPrice}
                      </span>
                    )}
                    <span className={styles.discountPrice}>
                      ₹{selectedVariant ? item.variantDiscountPrice : item.item.discountPrice}
                    </span>
                  </div>
                  <div className={styles.quantityControls}>
                    <button onClick={() => handleQuantityChange(item.itemId, -1, item.categoryName, item.variantId)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => handleQuantityChange(item.itemId, 1, item.categoryName, item.variantId)}>+</button>
                  </div>
                </div>
                <button
                  className={styles.removeItem}
                  onClick={() => handleRemoveItem(item.itemId, item.variantId)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </div>
            );
          })}
        </div>
        <div className={styles.additionalMessageSection}>
          <h3>Additional Message</h3>
          <textarea
            className={styles.additionalMessageInput}
            placeholder="Enter any specific requirements or instructions here...(Optional)"
            value={additionalMessage}
            onChange={(e) => setAdditionalMessage(e.target.value)}
          />
        </div>
        {/* <div className={styles.scheduleOrderSection}>
          <h3>Schedule Order (Optional)</h3>
          <input
            type="datetime-local"
            value={scheduleDateTime}
            onChange={handleDateTimeChange}
            className={styles.dateTimeInput}
          />
        </div> */}
        {!hideDeliveryDetails && <div className={styles.addressSection}>
          <h3>Delivery Details</h3>
          <button className={styles.locationButton} onClick={handleLocationClick}>
            <FontAwesomeIcon icon={faLocationArrow} /> Use Current Location
          </button>

          {addressSaved ? (
            <div>
              <p className={styles.savedAddress}>Address: {address.street}, {address.pincode}, {address.landmark}</p>
              <button className={styles.editAddressButton} onClick={() => setAddressSaved(false)}>
                <FontAwesomeIcon icon={faEdit} /> Edit Address
              </button>
            </div>
          ) : (
            <>
              <div className={styles.separator}>
                <div className={styles.separatorLine}></div>
                <div className={styles.separatorText}>OR</div>
                <div className={styles.separatorLine}></div>
              </div>
              {previousAddresses.length > 0 && (
                <button className={styles.selectAddressButton} onClick={() => setShowAddressPopup(true)}>
                  <FontAwesomeIcon icon={faHome} /> Select Address
                </button>
              )}
              <div className={styles.addressForm}>
                <input
                  type="text"
                  name="street"
                  value={address.street}
                  onChange={handleChange}
                  placeholder="Flat / House no / Floor / Building *"
                  required
                />
                <input
                  type="number"
                  name="pincode"
                  value={address.pincode}
                  onChange={handleChange}
                  placeholder="Pincode *"
                  required
                />
                {addressError && <p className={styles.errorText}>{addressError}</p>}
                <input
                  type="text"
                  name="landmark"
                  value={address.landmark}
                  onChange={handleChange}
                  placeholder="Nearby landmark (optional)"
                />
                <button className={styles.saveDetailsButton} onClick={handleSaveAddress}>
                  Save Details
                </button>
              </div>
            </>
          )}
        </div>}

        {showAddressPopup && (
          <div className={styles.addressPopupOverlay}>
            <div className={styles.addressPopupContent}>
              <h3><FontAwesomeIcon icon={faHome} /> Select Previous Address</h3>
              <ul className={styles.addressList}>
                {previousAddresses.map((addr, index) => (
                  <li key={addr.id} className={styles.addressItem} onClick={() => handleAddressSelect(addr)}>
                    <p>{addr.address}, {addr.pincode}</p>
                    {addr.landmark && <p>Landmark: {addr.landmark}</p>}
                  </li>
                ))}
              </ul>
              <button className={styles.addressClosePopupButton} onClick={() => setShowAddressPopup(false)}>Close</button>
            </div>
          </div>
        )}

        {availablePaymentModes.length > 0 && (
          <div className={styles.paymentModeSection}>
            <h3>Payment Mode</h3>
            {availablePaymentModes.map(mode => (
              <label key={mode.id}>
                <input
                  type="radio"
                  name="paymentMode"
                  value={mode.id}
                  onChange={handlePaymentModeChange}
                />
                {mode.label}
              </label>
            ))}
          </div>
        )}
        {error && <div className={styles.errorText}>{error}</div>}
        <div className={styles.couponSection}>
          <h3>Apply Coupon</h3>
          <p className={styles.couponHint} onClick={handleShowCouponPopup}>
            Looking for a coupon code?
          </p>
          <div className={styles.couponInputContainer}>
            <div className={styles.couponInputWrapper}>
              <input
                type="text"
                value={couponCode}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
                  setCouponCode(value);
                }}
                placeholder="Enter Coupon Code"
                className={styles.couponInput}
                disabled={isCouponApplied}  // Disable input when coupon is applied
              />
              <button
                onClick={isCouponApplied ? handleRemoveCoupon : handleApplyCoupon}
                className={styles.couponActionButton}
              >
                {isCouponApplied ? "Remove Coupon" : "Apply Coupon"}
              </button>
            </div>
            {isCouponApplied && (
              <div className={styles.couponAppliedMessage}>
                <p>Coupon applied successfully! 🎉 Discount: ₹{discount}</p>
              </div>
            )}
          </div>
          {couponMessage && <p className={styles.couponMessage}>{couponMessage}</p>}
        </div>
        {showCouponPopup && (
          <div className={styles.popup}>
            <div className={styles.popupContent}>
              <h4>Available Coupons</h4>
              <ul>
                {availableCoupons.map((coupon) => (
                  <li key={coupon.id}>
                    <span><b>{coupon.couponCode}</b></span>Get ₹{coupon.discount} off on orders above ₹{coupon.minimumOrder}
                    <button onClick={() => handleApplyCouponChange(coupon.couponCode)} className={styles.applyButton}>
                      Apply
                    </button>
                  </li>
                ))}
              </ul>
              <button onClick={() => setShowCouponPopup(false)} className={styles.couponCloseButton}>X</button>
            </div>
          </div>
        )}

        <div className={styles.paymentDetails}>
          <h2>Bill Summary</h2>
          <div>
            <span>Subtotal:</span>
            <span>₹{total}</span>
          </div>

          <div>
            <span>Delivery charges:</span>
            <span>₹{deliveryCharges}</span>
          </div>
          <div>
            <span>Taxes & other charges:</span>
            ₹{tax}
          </div>

          <div className={styles.totalAmount}>
            <span>Total:</span>
            ₹{grandTotalBeforeRoundOff}
          </div>
          {isCouponApplied && (
            <div>
              <span>Discount:</span>
              <span>-₹{discount}</span>
            </div>
          )}
          <div>
            <span>Round off Charge</span>
            -₹{roundOffAmount}
          </div>
          <div className={styles.totalAmount}>
            <span>Total Amount</span>
            ₹{grandTotal}
          </div>
        </div>
        <button className={styles.checkoutButton} onClick={handlePlaceOrder}>CHECKOUT</button>
        {validateDistanceAndAmount && <div className={styles.errorText}>{checkoutError}</div>}
      </div>
    </div>
  );
};

export default ViewCart;
