import React from "react";
import styles from "./Section2.module.css";
import image1 from "../../Images/chicken_tikka.png";

const Section2 = () => {
  return (
    <section className={styles.section2}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.colMd6}>
            <div className={styles.heroImage}>
              <img src={image1} alt="Chicken Tikka Image" />
            </div>
          </div>
          <div className={styles.colMd6}>
            <div className={styles.heroContent}>
              <h1>Welcome To Bawa's Chicken</h1>
              <p>
                Established in 1999, our restaurant has proudly been a culinary staple in the community, serving an exceptional blend of Mughlai, Chinese, and Tandoori dishes.
              </p>
              <div><a href="/about" className={styles.btn}>
                More About Us
              </a></div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;