import React, { useState, useEffect } from 'react';
import styles from './Section4.module.css';

const reviews = [
    {
        text: "Good food the things that you have done in your life; the knowledge or skill that you get from seeing or doing something",
        author: "Ranjeet Sharma"
    },
    {
        text: "The taste is awesome, and the staff is very polite and friendly We visit here quite often and regularly and the experience is always satisfying. A great place to celebrate your small and big special days...",
        author: "Akshi Kaur"
    },
    {
        text: "The ambience, Quality and quantity were upto mark, staff was friendly too. Must try there Rana or idk rada chicken(chicken served with keema mutton along with Garlic Naan and Butter Naan). Definitely go for it spot!!!",
        author: "Abhishek"
    },
    {
        text: "Been a customer for decades. This place serves the best quality tandoori items as well as the main course. Never goes out of fashion. Now also a fine dining option is available. Cheers.",
        author: "Deepankar Sarkar"
    },
    // Add more reviews as needed
];

const Section4 = () => {
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
        }, 5000); // Change review every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    return (
        <div className={styles.section}>
            <div className={styles.reviewContainer}>
                <h2>CUSTOMER REVIEWS</h2>
                <p className={styles.reviewText}><i>“{reviews[currentReviewIndex].text}"</i></p>
                <p className={styles.reviewAuthor}>{reviews[currentReviewIndex].author}</p>
            </div>
            
        </div>
    );
};

export default Section4;
