import React from 'react';
import styles from './AboutSection2.module.css';

const AboutSection2 = () => {
    return (
        <div className={styles.aboutSection}>
            <div className={styles.heading}>
                Our Story </div>
            <div className={styles.paragraph}>
                Established in 1999, our restaurant has proudly been a culinary staple in the community, serving an exceptional blend of Mughlai, Chinese, and Tandoori dishes.
            </div>
            <div className={styles.subheading}>We operate our business under these guiding principles:</div>
            <div className={styles.paragraph}>
            With a steadfast commitment to quality and tradition, we continue to deliver the same delightful flavors that have enchanted our guests for decades. Whether you crave the rich, aromatic spices of Mughlai cuisine, the bold and diverse tastes of Chinese food, or the smoky, grilled perfection of Tandoori specialties, our menu offers an authentic experience that remains unchanged and consistently delicious.
                </div>
        </div>
    );
};

export default AboutSection2;
