import React, { useState, useEffect } from 'react';
import styles from './Section1.module.css';
import image1 from '../../Images/veg_munchurian.jpg';
import image2 from '../../Images/veg_paneer_tikka.jpg';
import image3 from '../../Images/chicken_tikka.png';
import { useNavigate } from 'react-router-dom';

const images = [
    image1, image2, image3
];

const Section1 = () => {

    const navigate = useNavigate();

    const handleOrderButtonClick = () => {
        navigate('/order-now');
    };

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    return (
        <section className={styles.section1}>
            <div className={styles.overlay}></div>
            <img src={images[currentImageIndex]} alt="Hero" className={styles.heroImage} />
            <div className={styles.content}>
                <h1>Bawa Chicken : The Journey Of Spice</h1>
                <button className={styles.orderButton} onClick={handleOrderButtonClick}>Order Online</button>
            </div>
            <div className={styles.socialMedia}>
                <a href="https://www.instagram.com/bawa.chicken/" target="_blank" rel="noopener noreferrer">Instagram</a>
            </div>
        </section>
    );
};

export default Section1;
