import React, { useState } from 'react';
import styles from './Login.module.css';
import OTPVerification from './OTPVerification';
import axiosInstance from '../../api';
import { ClipLoader } from 'react-spinners'; // Import the loader

const Login = ({ show, handleClose, handleLogin }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fullName, setFullName] = useState('');
    const [showOTP, setShowOTP] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const [phoneError, setPhoneError] = useState(''); // Add state for phone number error
    const [errorMessage, setErrorMessage] = useState('');

    const handleChangePhoneNumber = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleChangeFullName = (event) => {
        setFullName(event.target.value);
    };

    const headers = {
        "Content-Type": "application/json", // Assuming you are sending JSON data
        "DEVICE-TYPE": "Web",
        "VER": "1.0"
    };

    const requestData = {
        mobilenumber: phoneNumber,
        fullName: fullName, // Send the hashed password
        client: process.env.REACT_APP_CLIENT
    };

    const validatePhoneNumber = (number) => {
        // Regex to check if the phone number is exactly 10 digits
        const phoneNumberPattern = /^\d{10}$/;
        return phoneNumberPattern.test(number);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validatePhoneNumber(phoneNumber)) {
            setPhoneError('Please enter the correct mobile number');
            return;
        }
        setPhoneError(''); // Clear any previous errors
        setLoading(true); // Start loading
        try {
            const response = await axiosInstance.post(process.env.REACT_APP_USER_CREATE, requestData, { headers });
            if (response.status === 200) {
                setShowOTP(true);
            } else {
                console.error('Failed to create user');
                setErrorMessage('Invalid OTP. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage(error.response?.data?.responseMessage || 'An error occurred');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleBackToLogin = () => {
        setShowOTP(false);
    };

    const handleLoginSuccess = () => {
        handleLogin(); // Update the parent state to reflect the logged-in status
    };

    const handleCloseClick = () => {
        setPhoneNumber(''); // Clear phone number
        setFullName(''); // Clear full name
        setShowOTP(false); // Close the OTP view
        handleClose(); // Call the parent handleClose function
    };

    if (!show) {
        return null;
    }

    return (
        <div className={styles.overlay}>
            {!showOTP ? (
                <div className={styles.loginBox}>
                    <button className={styles.closeButton} onClick={handleCloseClick}>X</button>
                    <div className={styles.iconHeader}>
                        {/* Add your icons here */}
                    </div>
                    <h2>Welcome</h2>
                    <p>Enter your phone number to proceed</p>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.inputGroup}>
                            <span className={styles.countryCode}>+91</span>
                            <input
                                type="tel"
                                className={styles.formInput}
                                placeholder="Enter Phone Number"
                                value={phoneNumber}
                                onChange={handleChangePhoneNumber}
                                required
                            />
                        </div>
                        {phoneError && <p className={styles.error}>{phoneError}</p>} {/* Show phone error */}
                        <div className={styles.inputGroup}>
                            <input
                                type="text"
                                className={styles.formInput}
                                placeholder="Full Name"
                                value={fullName}
                                onChange={handleChangeFullName}
                                required
                            />
                        </div>
                        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                        <button type="submit" className={styles.submitButton} disabled={loading}>
                            {loading ? <ClipLoader size={20} color="#fff" /> : 'Continue'}
                        </button>
                    </form>
                    <p className={styles.terms}>
                        By continuing you agree to our <a href="/terms-and-condition">Terms of Use</a> & <a href="/privacy-policy">Privacy Policy</a>
                    </p>
                </div>
            ) : (
                <OTPVerification
                    phoneNumber={phoneNumber}
                    handleBack={handleBackToLogin}
                    handleLoginSuccess={handleLoginSuccess}
                />
            )}
        </div>
    );
};

export default Login;
