import React from 'react';
import styles from './AboutSection4.module.css';
import { useNavigate } from 'react-router-dom';

const AboutSection4 = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/order-now');
    };

    return (
        <div className={styles.section}>
            <div className={styles.overlay}>
                <h1 className={styles.heading}>We Offer You an Unforgettable Dining Experience</h1>
                <button className={styles.button} onClick={handleButtonClick}>Order Online</button>
            </div>
        </div>
    );
};

export default AboutSection4;
