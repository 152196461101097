import React, { useEffect, useState } from 'react';
import axiosInstance from '../../api'; // Adjust path as needed
import styles from './CouponCode.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrashAlt, faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Header from '../Constant/Header/Header';
import Footer from '../Constant/Footer/Footer';

const CouponCode = () => {
    const [couponcodes, setCouponcodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newCouponCode, setNewCouponCode] = useState({ couponCode: '', minimumOrder: '', discount: '', customerAttempts: '' });
    const [errors, setErrors] = useState({ couponCode: '', minimumOrder: '', discount: '', customerAttempts: '' });
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const fetchCouponCodes = async () => {
            try {
                const response = await axiosInstance.post(`${process.env.REACT_APP_COUPON_LIST}`);
                if (response.data.responseStatus && response.data.responseObject) {
                    setCouponcodes(response.data.responseObject);
                }
            } catch (error) {
                console.error('Error fetching coupon codes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCouponCodes();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axiosInstance.post(`${process.env.REACT_APP_COUPON_DELETE}?id=${id}`, {});
            setCouponcodes(couponcodes.filter(coupon => coupon.id !== id));
        } catch (error) {
            console.error('Error deleting coupon:', error);
        }
    };

    const openPopup = () => {
        setIsPopupOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        document.body.style.overflow = 'auto';
        setNewCouponCode({ couponCode: '', minimumOrder: '', discount: '', customerAttempts: '' });
        setErrors({ couponCode: '', minimumOrder: '', discount: '', customerAttempts: '' });
    };

    const validate = () => {
        let isValid = true;
        const newErrors = { couponCode: '', minimumOrder: '', discount: '', customerAttempts: '' };

        if (!newCouponCode.couponCode.trim()) {
            newErrors.couponCode = 'Coupon Code is required';
            isValid = false;
        }

        if (!newCouponCode.minimumOrder.trim()) {
            newErrors.minimumOrder = 'Minimum Order Value is required';
            isValid = false;
        }

        if (!newCouponCode.discount.trim()) {
            newErrors.discount = 'Discount is required';
            isValid = false;
        }

        if (!newCouponCode.customerAttempts.trim()) {
            newErrors.customerAttempts = 'Customer Attempts is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSave = async () => {
        if (!validate()) return;

        setIsSaving(true);
        try {
            const payload = {
                ...newCouponCode,
                client: process.env.REACT_APP_CLIENT
            };
            await axiosInstance.post(`${process.env.REACT_APP_COUPON_CREATE}`, payload);
            setCouponcodes([...couponcodes, payload]);
            closePopup();
        } catch (error) {
            console.error('Error saving coupon code:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // If the field is couponCode, allow only uppercase letters and numbers
        if (name === 'couponCode') {
            const uppercaseValue = value.toUpperCase();
            const filteredValue = uppercaseValue.replace(/[^A-Z0-9]/g, ''); // Only allow uppercase letters and numbers
            setNewCouponCode({
                ...newCouponCode,
                [name]: filteredValue
            });
        } else {
            setNewCouponCode({
                ...newCouponCode,
                [name]: value
            });
        }
    };
    

    return (
        <div>
            <Header />
            <div className={styles.couponCodeSection}>
                <div className={styles.couponCodeOverlay}></div>
                <div className={styles.couponCodeText}>COUPONS</div>
            </div>
            <div className={styles.container}>
                <div className={styles.addButtonContainer}>
                    <button className={styles.addButton} onClick={openPopup}>
                        <FontAwesomeIcon icon={faPlus} /> Add Coupon
                    </button>
                </div>
                {loading ? (
                    <div className={styles.loader}>
                        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                        <p>Loading...</p>
                    </div>
                ) : couponcodes.length === 0 ? (
                    <p className={styles.noResults}>No Coupons found</p>
                ) : (
                    <div className={styles.cardContainer}>
                        {couponcodes.map(coupon => (
                            <div key={coupon.id} className={styles.card}>
                                <h3 className={styles.cardTitle}>{coupon.couponCode}</h3>
                                <p><strong>Discount:</strong> {coupon.discount}</p>
                                <p><strong>Minimum Order Value:</strong> {coupon.minimumOrder}</p>
                                <p><strong>Customer Attempts:</strong> {coupon.customerAttempts}</p>
                                <button
                                    className={styles.deleteButton}
                                    onClick={() => handleDelete(coupon.id)}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {isPopupOpen && (
                <div className={styles.popup}>
                    <div className={styles.popupContent}>
                        <h2>Add Coupon Code</h2>
                        <label>Coupon Code</label>
                        <input
                            type="text"
                            name="couponCode"
                            value={newCouponCode.couponCode}
                            onChange={handleChange}
                        />
                        {errors.couponCode && <p className={styles.error}>{errors.couponCode}</p>}
                        <label>Discount</label>
                        <input
                            type="number"
                            name="discount"
                            value={newCouponCode.discount}
                            onChange={handleChange}
                        />
                        {errors.discount && <p className={styles.error}>{errors.discount}</p>}
                        <label>Minimum Order Value</label>
                        <input
                            type="number"
                            name="minimumOrder"
                            value={newCouponCode.minimumOrder}
                            onChange={handleChange}
                        />
                        {errors.minimumOrder && <p className={styles.error}>{errors.minimumOrder}</p>}
                        <label>Customer Attempts</label>
                        <input
                            type="number"
                            name="customerAttempts"
                            value={newCouponCode.customerAttempts}
                            onChange={handleChange}
                        />
                        {errors.customerAttempts && <p className={styles.error}>{errors.customerAttempts}</p>}
                        <button
                            className={styles.saveButton}
                            onClick={handleSave}
                            disabled={isSaving}
                        >
                            {isSaving ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                                'Save'
                            )}
                        </button>
                        <button className={styles.cancelButton} onClick={closePopup}>
                            Cancel
                        </button>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
};

export default CouponCode;
